@value unit: var(--ring-unit);

.editButton {
  height: auto;
  margin-right: calc(unit * -2);
  margin-left: auto;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height-lowest);
}
