@value unit: var(--ring-unit);

.header {
  composes: row from '../../../../common/CollapsibleLine/CollapsibleLine.css';

  position: relative;

  justify-content: flex-start;

  margin-top: 0;

  margin-bottom: unit;
}

.title.title {
  font-size: inherit;
  line-height: inherit;
}

.root {
  margin-top: calc(unit * 3);
}

.limitWidth {
  max-width: var(--tc-content-width);
}

.archived,
.showArchivedProjectsButton {
  margin-top: calc(unit * 2);
}

.hideArchivedProjectsButton {
  padding-left: 0;

  color: var(--ring-secondary-color);
}
