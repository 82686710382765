@value unit: var(--ring-unit);

.delete {
  height: auto;
  margin: 0 calc(unit * -1) 0 auto;

  line-height: var(--ring-line-height-lower);
}

.restore {
  height: auto;
  margin: 0 calc(unit * -2) 0 auto;

  line-height: var(--ring-line-height-lower);
}
