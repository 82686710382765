@value unit: var(--ring-unit);

.container {
  min-height: calc(unit * 7.5);
  margin-bottom: unit;

  text-align: right;
}

.actions {
  display: flex;
}

.button {
  margin-left: unit;
}

.unsaved {
  margin-top: calc(unit * 2);

  color: var(--ring-warning-color);
}

.unsavedMarker {
  display: inline-block;

  margin-left: calc(unit / 2);
}
