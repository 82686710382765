@value unit: var(--ring-unit);

.container {
  padding-bottom: calc(unit * 4 - 2px);
}

.title.title {
  margin-bottom: calc(unit - 2px);
}

.changeVCS {
  padding: 0 calc(unit * 1.5);

  color: var(--ring-secondary-color);
}

.githubIcon {
  margin-right: calc(unit / 2);
}
