@value unit: var(--ring-unit);

.integration {
  position: relative;

  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit * 1.5);
}

.infoWrapper {
  display: contents;
}

.iconContainer {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;

  width: calc(unit * 2.5);
  height: calc(unit * 2.5);
  margin-right: unit;
}

.icon {
  display: block;
}

.disabled .icon {
  filter: grayscale(1) opacity(0.5);
}

.note {
  margin-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.disabled .name,
.disabled .note {
  color: var(--ring-secondary-color);
}

.deleted .name,
.deleted .note {
  text-decoration: line-through;
}
