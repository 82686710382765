@value unit: var(--ring-unit);

.columns {
  display: flex;

  width: 200%;
  max-height: 100%;
}

.primary,
.secondary {
  overflow-x: hidden;
  overflow-y: auto;
  flex-basis: 50%;

  box-sizing: border-box;

  padding: calc(unit * 1.5) calc(unit *4) calc(unit *4);
}

.secondary {
  transition: opacity 200ms ease-out;

  transform: translateX(-100%);
  pointer-events: none;

  opacity: 0;

  background-color: var(--ring-sidebar-background-color);
}

.secondaryVisible {
  pointer-events: initial;

  opacity: 1;
}
