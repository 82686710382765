@value font from '../../../../ring-globals.css';
@value unit: var(--ring-unit);

.header {
  max-width: var(--tc-content-width);
  margin-bottom: calc(unit * 2);
}

.heading {
  composes: arrowWrapper from '../../../common/ProjectOrBuildTypeIcon/ProjectOrBuildTypeIcon.css';
}

.heading.heading {
  margin: 0;
}

.star {
  margin-left: -2px;

  vertical-align: 3px;
}

.branchFilter {
  composes: font;

  flex: 0 0 auto;

  font-weight: normal;
}

.links {
  display: flex;
  align-items: baseline;
}

.linksSequenceLoader {
  display: flex;
  flex-direction: row-reverse;

  & > * {
    margin-right: unit;
  }

  & > *:first-child {
    margin-right: 0;
  }
}

.breadcrumbs {
  flex-grow: 1;
}

.breadcrumbsLink {
  font-size: var(--ring-font-size-smaller);
  line-height: 24px;
}

.subHeader {
  display: flex;
  align-items: baseline;

  width: 100%;
  min-height: calc(4 * unit);
}

.subHeaderItem {
  display: contents;
}

.actions {
  white-space: nowrap;
}

.investigation {
  overflow: hidden;
}

.description,
.systemProblems,
.pausedBuildTypeInfo,
.branchFilter,
.actions,
.investigation,
.healthItems {
  margin-top: unit;
}

.branchFilter,
.investigation,
.actions,
.healthItems {
  margin-right: unit;

  font-size: var(--ring-font-size-smaller);
  line-height: 24px;
}
