@value unit: var(--ring-unit);

.content {
  padding-bottom: unit;
}

.label {
  display: block;

  margin-top: calc(unit * 1.5);
}

.label:first-child {
  margin-top: 0;
}

.input {
  margin-top: unit;
}

.note {
  margin-top: calc(unit * 0.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.testConnection {
  display: flex;
  align-items: center;

  padding-top: calc(unit * 1.5);
}

.buttonTestConnection {
  align-self: flex-start;

  white-space: nowrap;
}

.connectionResult {
  padding-left: unit;

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-font-size-smaller);
}

.success {
  color: var(--ring-success-color);
}

.error {
  color: var(--ring-error-color);
}

.buttonsPanel {
  display: flex;
}

.delete {
  margin-left: auto;
}
