@value button from '@jetbrains/ring-ui/components/button/button.css';
@value unit: var(--ring-unit);

a.button:hover {
  text-decoration: inherit;
}

.sidebar {
  position: absolute;

  z-index: 1; /* Need for correct scrolling work. See TW-60668 */

  overflow: hidden; /* Fix wrong page size in Edge */

  border-right: 1px solid var(--ring-line-color);

  background-color: var(--ring-sidebar-background-color);
}

.sidebarPlaceholder {
  flex-grow: 1;
}

.sidebarFixed {
  position: fixed;
}

.sidebarPanelWrapper {
  display: flex;
  flex: 1 1 auto;
}

.sidebarPanel {
  flex: 1 1 auto;
}

.resizable {
  display: flex;
  flex-direction: column;
}

.resizableRightHandle {
  z-index: 20;
}

.mainPanel {
  position: relative;

  display: flex;
  flex-direction: column;

  height: 0;
}

.content {
  display: flex;
  flex-direction: column;

  flex-grow: 1;

  padding: calc(unit * 2) calc(unit * 4) 0 calc(unit * 4);
}

.collapsedSidebar .sidebarPanelWrapper {
  flex-grow: 0;
}

.collapsedSidebar .footer {
  flex-grow: 1;

  border-top: none;
}

.router {
  display: flex;
  flex-direction: column;

  flex-grow: 1;
}
