.jobParallelism {
  display: flex;

  align-items: center;
  justify-content: space-between;

  margin-bottom: calc(var(--ring-unit) * 3);

  font-weight: bold;
}

.jobParallelismInput {
  width: 48px;
  margin: -2px 0;

  & input[type='number'] {
    text-align: right;

    font-weight: normal;

    &::placeholder {
      text-align: right;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      margin: 0;
      appearance: none;
    }
  }
}
