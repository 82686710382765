@value unit: var(--ring-unit);

.wrapper {
  position: relative;

  margin-top: calc(unit * 2);
}

.edges {
  position: relative;
  fill: transparent;
  stroke: var(--ring-icon-color);

  pointer-events: none;
}
