@value font-smaller from '../../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.popup {
  width: calc(unit * 37);
  margin: calc(unit * 0.5) 0 0;
  padding: calc(unit * 0.5) 0 unit;
}

.link {
  composes: item from '../../../../../common/ActionsDropdown/ActionsDropdown.css';
}

.hoverLink {
  composes: hoverItem from '../../../../../common/ActionsDropdown/ActionsDropdown.css';
}

.text {
  composes: font-smaller;

  white-space: normal;

  color: var(--ring-secondary-color);
}
