.tcIcon {
  display: inline-block;

  background-repeat: no-repeat;

  line-height: normal;
}

.sprite {
  width: 13px;
  height: 13px;

  background-image: url('../../../../../web/resources/webComponents/teamcity-elements/icons/teamcity-sprite@2x.png');
  background-size: 240px 634px;
}

.spinning {
  & svg {
    position: relative;
    z-index: 4; /* should be unique for different composite layer */

    transform-origin: 50% 50%;
  }
}

.spinningPerson {
  composes: spinning;

  position: absolute;
  top: 0;
  left: 0;

  & path {
    display: none;
  }

  & :global(.secondary) {
    display: block;
  }

  & svg {
    transform-origin: 68.38% 68.75%;
  }
}

.staticPerson :global(.secondary) {
  display: none;
}

.spinning :global(.arrow) {
  display: none;
}

.detached {
  position: absolute;

  top: 0;
  left: 0;
}

.detached svg {
  & path {
    display: none;
  }

  & :global(.arrow) {
    display: block;
  }
}

.big .spinningPerson svg {
  transform-origin: 70% 70%;
}

.spinning.animate svg {
  animation: spin 1.8s linear infinite;
}

.wrapper {
  position: relative;
}

.spinning .staticPerson svg,
.spinning .detached svg {
  animation: none;
}

.spinning .staticPerson :global(.arrow) {
  display: block;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.gray {
  color: var(--ring-icon-secondary-color);
}

.green {
  color: var(--ring-icon-success-color);
}

.red {
  color: var(--ring-icon-error-color);
}

.warning {
  color: var(--ring-icon-warning-color);
}

.blue {
  color: var(--ring-main-color);
}

.darkblue {
  color: var(--tc-icon-main-color);
}

.gray.secondary {
  & :global(.secondary),
  & :global(.arrow) {
    fill: var(--ring-icon-secondary-color);
  }
}

.green.secondary {
  & :global(.secondary),
  & :global(.arrow) {
    fill: var(--ring-icon-success-color);
  }
}

.red.secondary {
  & :global(.secondary),
  & :global(.arrow) {
    fill: var(--ring-icon-error-color);
  }
}

.my {
  color: var(--ring-main-color);
}

.spinning.green {
  & .spinningPerson {
    color: var(--ring-icon-success-color);
  }
}

.spinning.red {
  & .spinningPerson {
    color: var(--ring-icon-error-color);
  }
}

.red.animate svg {
  animation-direction: reverse;
}

.personal {
  color: var(--ring-icon-secondary-color);
}

.arrowRight {
  transform: rotate(-90deg);
  transform-origin: 50% 55%;
}

.dropdownTrigger {
  composes: sprite;

  vertical-align: -2px;

  background-position: -160px -142px;
}

.dropdownTriggerSpecial {
  composes: sprite;

  width: 17px;

  height: 22px;

  background-position: 0 -460px;
}

.runningStatic.spinning svg {
  animation: none;
}
