@value unit: var(--ring-unit);

.dialog {
  z-index: calc(var(--ring-alert-z-index) + 1);
}

.dialogContent {
  width: calc(unit * 80);
}

.code {
  margin-top: unit;
}

.content {
  padding-bottom: calc(unit * 4);
}

.title.title {
  font-size: inherit;
  line-height: inherit;
}
