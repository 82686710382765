@value unit: var(--ring-unit);

.aside {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  display: flex;

  overflow: hidden;
  align-items: flex-end;
  flex-direction: column;

  box-sizing: border-box;

  height: 100%;
  padding-bottom: calc(unit * 4);

  padding-left: calc(unit * 4);
}

.sidebar {
  min-height: 300px;

  border: 1px solid var(--ring-line-color);

  border-radius: var(--ring-border-radius);
  background-color: var(--ring-sidebar-background-color);
  box-shadow: 0 2px 8px 0 var(--ring-popup-shadow-color);
}
