.secondary {
  color: var(--ring-secondary-color);
}

.link {
  position: relative;

  overflow: hidden;
}

.othersAvatar {
  transition: opacity var(--ring-fast-ease);

  opacity: 0.5;
}

.linkWrapper:hover .othersAvatar {
  transition: none;

  opacity: 1;
}

.brancheIcon {
  margin-left: calc(unit / 2);
}
