@value unit: var(--ring-unit);

.heading {
  display: flex;
  align-items: baseline;

  margin-bottom: unit;
}

.delete {
  height: auto;
  margin-right: calc(unit * -1);
  margin-left: auto;

  line-height: var(--ring-line-height-lower);
}

.input {
  margin-bottom: calc(unit * 3);

  background-color: var(--ring-content-background-color);
}

.label {
  font-weight: bold;
}

.inputWrapper {
  position: relative;
}
