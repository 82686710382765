@value unit: var(--ring-unit);

.inputWrapper {
  padding-right: calc(unit * 20);
}

.inputSecretName {
  display: block;

  padding-bottom: calc(unit * 1.5);

  & label {
    display: block;

    padding-bottom: unit;
  }
}

.inputSecretName .errorText {
  display: none;
}

.buttonPanel {
  display: flex;
}

.buttonPanelDeleteButton {
  position: relative;

  flex: 1;

  margin-right: calc(unit * -1);

  text-align: right;
}
