@value unit: var(--ring-unit);

.container {
  position: relative;
}

.main {
  overflow: auto;

  box-sizing: border-box;
  height: 100%;
  margin-left: calc(unit * -1);
}
