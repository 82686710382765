@value font from '../../../../../ring-globals.css';

.buildActions {
  composes: font;
}

.popupLabel {
  padding-bottom: 0;
}

.button {
  padding-bottom: 2px;
}
