@value unit: var(--ring-unit);

.grid {
  position: relative;

  overflow: hidden;

  margin-left: calc(unit * -4);
  padding-left: calc(unit * 4);
}

.column {
  position: relative;

  padding: calc(unit / 2) unit;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
}

.columnValue {
  pointer-events: none;

  opacity: 0;

  &.visible {
    pointer-events: unset;

    opacity: 1;
  }
}

.row {
  display: contents;

  &:hover {
    & .columnValue {
      opacity: 1 !important;
    }
  }
}

.column:hover::after {
  position: absolute;
  z-index: -1;
  top: -100vh;
  left: 0;

  width: 100%;
  height: 200vh;

  content: '';

  opacity: 0;

  background-color: var(--ring-hover-background-color);
}

.column:hover::before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: -100vw;

  width: 200vw;
  height: 100%;

  content: '';

  opacity: 0;

  background-color: var(--ring-hover-background-color);
}

.column:hover {
  /* stylelint-disable-next-line selector-max-specificity */
  &.build::after,
  &.build::before,
  &.header.parameterValue::after,
  &.body.parameterValue::before {
    opacity: 1;
  }
}

.header {
  border-top: 1px solid #dfe5eb;
  border-bottom: 1px solid #dfe5eb;

  &.parameterName {
    color: var(--ring-secondary-color);

    font-weight: normal;
  }
}

.parameterName,
.parameterValue {
  padding-right: calc(unit * 2);

  white-space: nowrap;

  font-weight: 600;
}
