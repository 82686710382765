@value unit: var(--ring-unit);

.icon {
  margin-right: calc(unit / 2);
}

.disabled {
  color: var(--ring-secondary-color);
}

.disconnected {
  color: var(--ring-error-color);
}

.disconnectedIcon {
  color: var(--ring-icon-error-color);
}
