@value unit: var(--ring-unit);

.heading {
  display: flex;
  align-items: baseline;

  margin-bottom: unit;
}

.delete {
  margin-right: calc(unit * -1);
  margin-left: auto;
}

.deleteButton,
.restore {
  height: auto;

  line-height: var(--ring-line-height-lower);
}

.deleted {
  margin-left: auto;

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.restore {
  margin-right: calc(unit * -1.5);
  padding: 0 calc(unit * 1.5);
}
