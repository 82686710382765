@value unit: var(--ring-unit);

.agent {
  position: relative;

  display: flex;

  align-items: center;

  margin-bottom: calc(var(--ring-unit) * 3);
}

.selectPopup {
  --ring-font-size: 14px;
}

.agentLabel {
  margin-right: calc(unit / 2);

  font-weight: bold;
}

.selectItem svg {
  width: 14px;
  height: 14px;
}

.selectItem div:first-child span {
  width: 13px;
}

.info {
  height: auto;

  line-height: inherit;
}
