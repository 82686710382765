@value unit: var(--ring-unit);

.warning {
  display: inline-block;

  margin-top: calc(unit * 2);

  color: var(--ring-secondary-color);

  line-height: calc(unit * 3);
}

.button {
  padding: 0 calc(unit / 2);

  font-size: 13px;
}

.error {
  color: var(--ring-error-color);
}
