@value unit: var(--ring-unit);

.branchRules {
  display: flex;
  align-items: baseline;

  margin-top: calc(unit * 0.5);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.editButton {
  height: auto;
  margin-right: calc(unit * -2);
  margin-left: auto;

  color: var(--ring-secondary-color);

  line-height: inherit;
}
