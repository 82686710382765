@value switchWrapper from '@jetbrains/ring-ui/components/toggle/toggle.css';
@value label from '@jetbrains/ring-ui/components/input/input.css';
@value unit: var(--ring-unit);

.inputContainer .label {
  margin-bottom: unit;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.input {
  background-color: var(--ring-content-background-color);
}

.multiline {
  display: block;

  min-height: calc(unit * 8);
}

.monospace {
  font-family: var(--ring-font-family-monospace);
  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.toggleable {
  margin-top: unit;
}

.container {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.inline {
  display: flex;
  align-items: center;

  white-space: nowrap;
}

.inline .input {
  width: calc(100% - unit / 2);
  margin: -2px 0 -2px calc(unit / 2);
}

.description {
  margin-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.toggle {
  display: flex;
  align-items: center;
}

.toggle .switchWrapper {
  margin-left: auto;
}
