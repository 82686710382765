@value unit: var(--ring-unit);

.wrapper {
  max-width: var(--tc-content-width);
}

.header {
  composes: header from '../ProjectPage/ProjectPageHeader/ProjectPageHeader.css';
}

.headerWrapper {
  composes: headerWrapper from '../ProjectPage/ProjectPageHeader/ProjectPageHeader.css';

  margin-top: calc(unit * 3.5);
  margin-bottom: unit;
}
