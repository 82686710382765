@value unit: var(--ring-unit);

.heading {
  display: flex;

  margin-bottom: calc(unit * 4);
}

.title.title {
  margin-bottom: calc(unit / 2);
}

.branchSelect {
  height: calc(unit * 3);
  margin-top: calc(unit / 2);
}

.branchSelectButton {
  & > button[type='button'] {
    padding: 0;
  }

  & > button {
    background-color: transparent;

    &:hover {
      color: var(--ring-link-hover-color);
      background-color: transparent;
    }

    & svg {
      margin-right: calc(unit / 2);
    }
  }
}

.actions {
  margin-left: auto;
}

.button {
  margin-left: unit;
}
