@value unit: var(--ring-unit);
@value jobWidth: 196px;
@value jobHeight: 80px;

.container {
  position: absolute;
}

.job {
  --text-wrapper-background: rgba(var(--ring-content-background-components), 0.7);

  position: relative;

  display: flex;
  align-items: flex-start;
  flex-direction: column;

  box-sizing: border-box;
  width: jobWidth;
  height: jobHeight;
  padding: calc(unit / 2) unit;

  text-decoration: none;

  color: var(--ring-text-color);

  border: 1px solid transparent;

  border-radius: var(--ring-border-radius);
  outline-color: var(--ring-border-hover-color);

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height-lower);

  &:hover {
    --text-wrapper-background: rgba(var(--ring-hover-background-components), 0.7);

    text-decoration: none;

    background-color: var(--ring-hover-background-color);
  }
}

.name {
  display: -webkit-box;
  overflow: hidden;

  text-overflow: ellipsis;
  word-break: break-word;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.top,
.name {
  position: relative;
  z-index: 1;

  border-radius: var(--ring-border-radius);
  backdrop-filter: blur(1px);

  background-color: var(--text-wrapper-background);
}

.jobIcon {
  color: var(--ring-secondary-color);
}

.trashIcon {
  color: var(--ring-text-color);
}

.statusIcon {
  color: var(--ring-success-color);
}

.reusedIcon {
  opacity: 0.5;
}

.spinningIcon svg {
  position: relative;
  z-index: 4; /* should be unique for different composite layer */

  animation: spin 1.8s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.status {
  margin-top: calc(unit / 2);

  color: var(--ring-success-color);

  font-size: var(--ring-font-size-smaller);
}

.reusedStatus {
  opacity: 0.8;
}

.selected,
.hoveredInSidebar {
  --text-wrapper-background: rgba(var(--ring-hover-background-components), 0.7);

  background-color: var(--ring-hover-background-color);

  & .jobIcon {
    color: var(--ring-main-color);
  }
}

.selected {
  font-weight: bold;
}

.runSelected {
  outline: solid 2px var(--ring-main-color);
}

.error {
  color: var(--ring-error-color);
}

.errorMessage {
  padding-top: unit;

  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);

  font-weight: normal;
  line-height: var(--ring-font-size-smaller);
}

.selectedLabel,
.editedLabel,
.deletedLabel {
  display: inline-block;

  margin-left: 2px;
  padding-right: calc(unit / 2);

  font-size: var(--ring-font-size-smaller);
  font-weight: bold;

  line-height: var(--ring-line-height-lowest);
}

.selectedLabel {
  color: var(--ring-main-color);
}

.editedLabel {
  color: var(--ring-warning-color);
}

.deletedLabel {
  color: var(--ring-text-color);
}

.deleted {
  text-decoration: line-through;

  color: var(--ring-secondary-color);
}
