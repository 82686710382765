@value unit: var(--ring-unit);

.trigger {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.triggerTime {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.triggerDetail {
  margin-top: calc(unit / 2);

  transition: all 300ms ease-in-out;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.triggerDetailDisabled {
  opacity: 0.4;
}

.triggerTimeRightButton {
  white-space: nowrap;
}

.triggerEditButton {
  height: auto;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height-lower);
}
