@value unit: var(--ring-unit);

.groupDescription {
  padding: 0 calc(unit * 4);
  padding-bottom: unit;

  font-size: 13px;

  line-height: 20px;
}
