@value unit: var(--ring-unit);

.section {
  position: relative;

  margin-bottom: calc(unit * 3);

  &:last-child {
    margin-bottom: 0;
  }
}

.collapsibleRegion {
  margin-top: calc(unit * 1.5);
}

.withNotice {
  margin-top: calc(unit * 0.5);
}
