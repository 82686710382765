@value unit: var(--ring-unit);

.wrapper {
  position: relative;
}

.trigger {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: calc(unit * 1.5);
}
