@value section from '../../../../SettingsBlock/SettingsBlock.css';
@value unit: var(--ring-unit);

.add {
  margin-bottom: calc(unit * 3);
}

.steps {
  margin-bottom: calc(unit * 1.5);
}

.stepWrapper {
  --ring-font-size: 14px;

  z-index: 1;

  margin: 0 calc(unit * -4) 0;
  padding: calc(unit * 1.5) calc(unit * 4);

  background-color: var(--ring-sidebar-background-color);

  font-size: var(--ring-font-size);
}

.stepWrapper > .section {
  margin-top: 0;
}

.title.title {
  margin-top: 0;
  margin-bottom: calc(unit * 1.5);

  font-size: var(--ring-font-size);
  font-weight: bold;
  line-height: var(--ring-line-height);
}

.separator,
.separatorEnd {
  border-top: 1px solid var(--ring-line-color);
}

.separatorEnd {
  margin-bottom: calc(unit * 1.5);
}
