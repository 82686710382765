@value font-smaller-lower from '../../../../../ring-globals.css';
@value unit: var(--ring-unit);

.container {
  composes: font-smaller-lower;

  white-space: pre-wrap;

  color: var(--ring-secondary-color);
}

.activate {
  margin-left: unit;

  color: #008eff;

  &.lineBreak {
    display: block;

    margin-left: 0;
  }
}

.icon {
  margin-right: calc(unit / 2);
}

.dropwdown {
  margin-left: unit;

  white-space: nowrap;

  & button {
    padding-left: 0;
  }
}

.dropwdownActive {
  color: var(--ring-link-hover-color);
}

.popup {
  max-width: 600px;
  padding: unit calc(2 * unit) calc(2 * unit);

  color: inherit;
}

.chevron {
  margin-left: 2px;
}
