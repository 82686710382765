@value unit: var(--ring-unit);

.heading {
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;

  width: fit-content;
  max-width: 600px;

  padding-right: calc(unit * 2);
  padding-bottom: unit;
  padding-left: unit;

  background-color: var(--ring-content-background-color);
}

.title.title {
  margin: 0;
}

.titleWrapper {
  display: flex;
  align-items: baseline;
}

.headerLink {
  padding: 0 calc(unit * 1.5);
}

.active {
  font-weight: bold;
}

.description {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);

  & a {
    color: var(--ring-secondary-color);
  }
}

.numberErrors {
  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
}

.warningMessage {
  display: flex;

  align-items: center;

  padding-top: calc(unit / 2);

  color: var(--ring-warning-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-font-size-smaller);
  fill: var(--ring-warning-color);

  & svg {
    width: 12px;

    margin-right: calc(unit / 2);
  }
}
