.unsavedMarker {
  display: inline-block;

  width: 10px;
  height: 10px;

  border-radius: 5px;
  background-color: var(--ring-icon-warning-color);
}

.inSidebar {
  position: absolute;
  top: 5px;
  right: -21px;
}

.atInput {
  top: 7px;
}
