@value unit: var(--ring-unit);

.triggerNewChangesToggleWrapper {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.triggerNewChangesToggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
