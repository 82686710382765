@value switchWrapper from '@jetbrains/ring-ui/components/toggle/toggle.css';
@value unit: var(--ring-unit);

.container {
  position: relative;

  margin-bottom: calc(unit * 1.5);
}

.toggle {
  display: flex;
  align-items: center;
}

.toggle .switchWrapper {
  margin-left: auto;
}

.description {
  margin-top: calc(unit / 2);

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
