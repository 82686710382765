@value unit: var(--ring-unit);

.parameter {
  position: relative;

  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit * 1.5);

  line-height: calc(unit * 3);
}

.withIcon {
  margin-top: unit;

  &:first-child {
    margin-top: 0;
  }
}

.icon {
  margin-right: unit;

  &.icon svg {
    vertical-align: -5px;
  }
}

.edit,
.remove,
.restore {
  height: auto;
  margin-right: calc(unit * -2);
  margin-left: auto;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height-lower);
}

.inputContainer {
  max-width: 50%;
  margin: 0 -2px;
}

.inputs {
  display: flex;

  width: calc(100% - 60px);

  margin: -2px 0;
}

.input {
  text-overflow: ellipsis;
}

.deleted,
.deleted .input {
  text-decoration: line-through;

  color: var(--ring-secondary-color);
  -webkit-text-fill-color: var(--ring-secondary-color);
}

.input:disabled {
  background-color: transparent;
}

.parameter:not(:focus-within) .input {
  border-color: transparent;
  background-color: transparent;
}

.parameter:focus-within .input {
  background-color: var(--ring-content-background-color);
}

.parameter:focus-within .edit,
.parameter:not(:focus-within) .remove {
  display: none;
}
