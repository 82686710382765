@value unit: var(--ring-unit);

.page {
  position: relative;

  max-width: var(--tc-content-width);
}

.heading {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  margin-bottom: calc(2 * unit);
}

h1.header {
  margin-bottom: 0;
}

.titleExplanation {
  font-weight: 200;
}

.description {
  margin-top: calc(0.5 * unit);
}

.editButton {
  flex-shrink: 0;

  margin-top: 3px;

  margin-bottom: 0;

  margin-left: calc(1.5 * unit);

  font-weight: normal;
}
