@value unit: var(--ring-unit);

.filter {
  display: flex;
  align-items: baseline;
}

.state {
  margin-right: calc(unit * 2);
}

.parameters {
  margin-right: calc(unit * 2);

  color: var(--ring-main-color);
}
