.historyLink {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.historyChevron {
  margin-left: -3px;
}

.historyChevron.historyChevron > svg {
  vertical-align: -4px;
}
