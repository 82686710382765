@value unit: var(--ring-unit);

.container {
  margin-bottom: unit;
}

.collapsibleContainer {
  margin-bottom: 0;
}

.header {
  position: relative;

  display: flex;
  align-items: baseline;
}

.title {
  margin: 0;

  padding: 2px 0 0;

  font-weight: bold;
}

h2.title {
  flex-shrink: 0;

  margin-bottom: 0;

  font-size: var(--ring-font-size);
  line-height: var(--ring-line-height);
}

.collapsible {
  margin-left: calc(unit * -4);
  padding-top: 0;
  padding-left: 14px;
}

.deleted {
  text-decoration: line-through;

  color: var(--ring-secondary-color);
}

.toggle {
  height: auto;
  padding-left: 0;

  color: inherit;

  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

.toggleWithEditing {
  padding-right: 2px;
}

.toggleIcon.toggleIcon {
  color: var(--ring-icon-secondary-color);
}

.handle {
  position: absolute;
  top: -1px;
  left: -30px;

  padding: 0;

  opacity: 0;
  color: var(--ring-icon-color);
}

.container:hover .handle {
  opacity: 1;
}

.editNameButton {
  height: auto;

  margin-left: auto;

  padding: 0;

  color: var(--ring-secondary-color);

  line-height: var(--ring-line-height-lower);
}

.right {
  margin-left: 2px;
}

.detailsLink {
  display: inline-block;

  margin-top: 2px;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.input {
  margin: -2px 0 -2px 2px;

  background-color: var(--ring-content-background-color);
}

.collapsedNote {
  overflow: hidden;

  margin-left: calc(unit / 2);

  white-space: nowrap;
  text-overflow: ellipsis;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}

.unsavedMarker {
  margin-left: calc(unit / 2);
}
