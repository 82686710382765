@value unit: var(--ring-unit);

.moreButton {
  margin-left: unit;
}

.moreButtonPopup {
  margin-top: calc(0.5 * unit);
}

.dropdown {
  line-height: var(--ring-line-height);
}

.anchor {
  color: var(--ring-text-color);
}

.chevron {
  margin-left: 2px;

  line-height: normal;
}

.placeId {
  display: inline-block;
}
