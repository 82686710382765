@value unit: var(--ring-unit);

.header {
  display: flex;
}

.title.title {
  margin: 0 0 calc(unit * 1.5);
}

.actions {
  display: flex;
}

.action {
  margin-right: unit;
}

.save {
  margin-left: auto;
}

.yaml {
  margin: calc(unit * 2) calc(unit * -4) 0;

  border-top: 1px solid var(--ring-line-color);
}

.yaml code {
  padding: unit calc(unit * 4);

  background-color: var(--ring-content-background-color);
}
