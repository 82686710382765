@value font-smaller, font-smaller-lower from '../../../../ring-globals.css';
@value wrapper-width: calc(unit * 14);
@value unit: var(--ring-unit);

.buildDuration {
  composes: font-smaller;

  display: inline-flex;
  align-items: baseline;
  justify-content: flex-end;

  margin-left: calc(0px - unit);
  padding-left: unit;

  text-align: right;

  white-space: nowrap;

  font-weight: normal;
}

.placeholder {
  width: wrapper-width;

  &::before {
    content: '.';

    color: transparent;
  }
}

.low {
  composes: font-smaller-lower;
}

.text {
  padding-right: unit;
}

.textForOvertime {
  padding-right: 0;
  padding-left: 20px;
}

.icon {
  position: absolute;
  top: 1px;
  left: 2px;

  color: inherit;
}

.wrapper {
  composes: font-smaller;

  position: relative;
  z-index: 1;

  overflow: hidden;

  width: wrapper-width;

  margin: 0;
  padding: 0 0 1px;

  cursor: default;

  white-space: nowrap;

  border-radius: var(--ring-border-radius);
}

.wrapperSuccess {
  color: var(--ring-text-color);
  background-color: var(--tc-success-background-color);

  & .icon {
    color: var(--tc-icon-main-color);
  }
}

.wrapperFailure {
  color: var(--ring-error-color);
  background-color: var(--tc-error-background-color);

  & > .icon {
    color: var(--ring-icon-error-color);
  }
}

.wrapperSuccess.withBorder {
  box-shadow: 0 0 0 1px rgba(122, 204, 139, 0.5) inset;
}

.wrapperFailure.withBorder {
  box-shadow: 0 0 0 1px rgba(194, 39, 49, 0.5) inset;
}

@media (min-resolution: 2dppx) {
  .wrapperSuccess.withBorder {
    box-shadow: 0 0 0 0.5px var(--tc-success-progress-color) inset;
  }

  .wrapperFailure.withBorder {
    box-shadow: 0 0 0 0.5px var(--ring-error-color) inset;
  }
}

.wrapperOvertime {
  text-align: left;
}

.progress {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  overflow: hidden;

  height: calc(var(--ring-line-height) + 1px);

  text-align: right;

  border-radius: var(--ring-border-radius) 0 0 var(--ring-border-radius);
  background-color: var(--tc-success-progress-color);
}

.progressFailure {
  color: var(--ring-white-text-color);
  background-color: var(--ring-error-color);
}

.progressOvertime {
  text-align: left;
}

.startedFinished {
  color: var(--ring-secondary-color);
}

.durationFinished {
  margin-left: unit;
}

.firstRunSuccessful {
  color: var(--ring-success-color);
}

.firstRunFailure {
  color: var(--ring-error-color);
}

.exactDuration {
  display: inline-block;

  min-width: calc(12 * unit);

  margin-left: 0;
}

.exactStarted {
  margin-left: 0;
}
