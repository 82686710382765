@value unit: var(--ring-unit);

.dialog {
  width: calc(70vw - unit * 6 * 2);
  height: calc(70vh - unit * 2 * 2);
}

.overlay {
  padding: 0;

  background-color: var(--tc-message-background-color);
}

.wrapper {
  display: flex;

  overflow: hidden;
  overflow-y: auto;
  flex-direction: column;

  height: 100%;
  padding-bottom: calc(unit * 4);
}

.header {
  padding: calc(unit * 2) calc(unit * 4) calc(unit * 4);
}

.noFiles {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;

  color: var(--ring-secondary-color);
}
