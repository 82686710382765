@value desriptionMinWidth: calc(unit * 20);
@value buildTypeNameMinWidth: calc(unit * 20);
@value unit: var(--ring-unit);

.star {
  padding-right: calc(unit / 4);
  padding-left: calc(unit / 4);

  transition: opacity var(--ring-fast-ease);

  opacity: 0;
}

.root:hover .star,
.root:focus-within .star {
  transition: none;

  opacity: 1;
}

.button {
  position: absolute;
}

.root {
  composes: not-comment-wrapper from '../../../../../common/Builds/Build/Build.css';

  position: relative;

  display: flex;
  align-items: baseline;

  box-sizing: border-box;

  min-width: 0;

  margin: calc(unit / 4) calc(unit * -2) calc(unit / 4);

  cursor: pointer;

  border-radius: 3px;

  grid-column: start / end;

  & > * {
    margin-top: 0;
  }

  &::before {
    position: absolute;

    z-index: -1;
    top: 0;

    display: block;

    width: 100%;
    height: 100%;
    margin: 0 calc(unit * -4);
    padding: 0 calc(unit * 4);

    content: '';
  }

  &:hover {
    &::before {
      background-color: var(--ring-hover-background-color);
    }
  }

  &:focus{
    outline: none;

    &::before {
      box-shadow: inset 2px 0 var(--ring-main-color);
    }
  }
}

.expanded {
  border-bottom: none;
  border-radius: 3px 3px 0 0;
}

.chevron {
  composes: arrow from '../../../../../common/CollapsibleLine/CollapsibleLine.css';

  top: 5px;
  left: -17px;
}

.caption {
  width: 100%;
  max-width: 100%;
  padding-right: calc(unit / 2);

  font-size: 13px;
}

.buildTypeInfo {
  display: flex;
  align-items: baseline;

  flex: 1 1 desriptionMinWidth;

  width: 100%;
  min-width: calc(unit * 9 + desriptionMinWidth);

  max-width: calc(100% - unit * 2 - 1px);

  margin-bottom: calc(unit / 4);
  padding: 0;
}

.buildTypeName.buildTypeName {
  display: flex;
  overflow: hidden;

  align-items: baseline;

  margin: 0 -2px 0 0;
  padding: 0 2px 0 0;

  font: inherit;
  font-weight: bold;
}

.description {
  flex-shrink: 10;

  min-width: desriptionMinWidth;
  padding-right: calc(unit / 2);
}

.noBuilds {
  min-width: calc(unit * 8);
  padding-right: calc(unit * 2);
}

.description,
.noBuilds {
  padding-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
}

.systemProblems {
  flex-shrink: 10;

  min-width: desriptionMinWidth;

  padding: 0 unit;
}

.link {
  max-width: calc(100% - unit * 2);
  margin: 2px 0;

  color: var(--ring-text-color);

  line-height: 22px;
}

.changes {
  display: inline-flex;

  margin-right: calc(unit * 2);

  font-weight: normal;
}

.runColumn {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;

  width: auto;
  min-width: auto;

  margin-left: auto;
  padding-right: calc(unit * 2);
}

.oneLine {
  composes: oneLine from '../../../../../common/Builds/Build/Build.css';

  & .caption {
    min-width: calc(unit * 3);
  }
}

.actions {
  margin: 0 calc(unit * -1) 0 unit;
}

.details {
  display: contents;
}
