@value unit: var(--ring-unit);

.dependencies {
  margin: -2px 0;
}

.jobDependency {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;

  min-height: calc(unit * 3);

  margin: 0 calc(unit * -4);
  padding: calc(unit / 4) calc(unit * 4);
}

.jobDependency:hover {
  background-color: var(--ring-hover-background-color);
}

.jobDependencyCheckboxLabel {
  flex: 1;
}

.unsavedMarker {
  top: 50%;
  right: calc(unit * 1.4);

  transform: translate(0, -50%);
}

.editButton {
  height: auto;
  padding-left: unit;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lower);
}

.editFilesSelect {
  font-size: var(--ring-font-size-smaller);
}

.editFilesSelectButton {
  color: var(--ring-secondary-color);
}
