@value lineClamp from '../../../../ring-globals.css';
@value maxLines: 3;

.icon {
  color: var(--ring-icon-error-color);
}

.description {
  composes: lineClamp;

  max-height: calc(var(--ring-line-height-lowest) * maxLines + 1px);

  color: var(--ring-text-color);

  -webkit-line-clamp: maxLines;
}
