@value unit: var(--ring-unit);

.urlSection {
  display: flex;
  align-items: baseline;

  margin-bottom: calc(unit * 1.5);
}

.input {
  margin: -2px 0;

  background-color: var(--ring-content-background-color);
}

.branchSelectButton {
  height: auto;

  color: var(--ring-text-color);

  line-height: inherit;
}
