@value unit: var(--ring-unit);

.sidebarSearch {
  position: relative;

  & button {
    padding-right: unit;
  }
}

.empty:not(:focus-within)::after {
  position: absolute;
  top: 0;
  right: calc(unit * 2);

  content: 'Q';

  color: var(--ring-disabled-color);

  line-height: calc(unit * 3);
}

.wrapper {
  padding: unit calc(2 * unit) 0 30px;
}

.edit {
  &.empty::after {
    content: none;
  }
}

.searchInputHint {
  margin-top: calc(unit / 2);
}
