@value unit: var(--ring-unit);

.page {
  display: flex;

  padding-bottom: calc(unit * 4);
}

.pipelineList {
  min-width: calc(unit * 100);
  max-width: calc(unit * 180);
  margin: auto;
}
