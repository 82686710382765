@value unit: var(--ring-unit);

.showAdvanced {
  height: auto;
  margin-left: calc(unit * -2);

  line-height: var(--ring-line-height-lowest);

  &:hover {
    color: var(--ring-link-hover-color);
  }
}

.delete {
  margin-right: calc(unit * -1);
}

.restore {
  margin-right: calc(unit * -2);
}

.delete,
.restore {
  height: auto;

  line-height: var(--ring-line-height-lower);
}

.failureLine {
  display: flex;
  align-items: baseline;

  margin-top: calc(unit / 2);
}

.icon {
  margin-right: calc(unit / 2);
}

.failure {
  color: var(--ring-error-color);
}

.logLink {
  margin-left: auto;

  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  line-height: var(--ring-line-height-lowest);
}
