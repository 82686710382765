@value resetButton, font-smaller-lower, ellipsis from '../../../../ring-globals.css';
@value chevronWidth: 24px;
@value unit: var(--ring-unit);

.button {
  composes: resetButton;

  width: 100%;
}

.projectsTreeItem {
  position: relative;

  display: block;

  overflow: hidden;

  color: var(--ring-text-color);

  border-radius: 0;
  outline: none;

  &:hover {
    color: var(--ring-text-color);
    background-color: var(--ring-selected-background-color);
  }
}

.checkbox {
  display: flex;
  align-items: baseline;
}

.checkboxCell {
  top: 2px;

  flex-shrink: 0;
}

.legacyCheckbox {
  position: relative;
  top: 1px;

  flex-shrink: 0;

  outline: none;  /* the whole line is highlighted on focus */
}

.checkboxLabel {
  min-width: 0;
}

.content {
  display: flex;

  align-items: baseline;

  height: calc(3 * unit);

  padding-right: unit;

  line-height: calc(2.5 * unit);

  & > * {
    flex-shrink: 0;
  }
}

.name {
  composes: ellipsis;

  flex: 1 1 auto;
}

.archived .name {
  color: var(--ring-secondary-color);
}

.active {
  background-color: var(--ring-selected-background-color);

  font-weight: bold;
}

.action {
  cursor: pointer;
}

.selected {
  background-color: var(--ring-selected-background-color);
}

.projectsTreeItem:focus,
.projectsTreeItemParent:focus .selected {
  box-shadow: inset 2px 0 var(--ring-main-color);
}

.chevronButton {
  position: absolute;
  z-index: 1;

  padding: 0 5px;

  & .chevronIcon {
    transition: none;

    color: var(--ring-icon-secondary-color);
  }

  &:hover,
  &.hovered {
    & .chevronIcon {
      color: var(--ring-icon-hover-color);
    }
  }
}

.fakeChevronButton {
  flex: 0 0 chevronWidth;

  width: chevronWidth;
  height: calc(2 * unit);
}

.title {
  display: flex;

  margin: calc(2 * unit - 3px) 0 7px 0;

  padding: 0 6px;

  letter-spacing: 2px;

  color: var(--ring-text-color);

  font-size: var(--ring-font-size-smaller);

  line-height: calc(3 * unit);
}

.row:first-child .title {
  margin-top: 0;
}

.server {
  composes: ellipsis;

  padding: calc(2 * unit) calc(unit + 2px) 3px calc(2 * unit);
}

.serverTitle {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size);
}

.serverDetails {
  font-size: var(--ring-font-size-smaller);

  line-height: calc(unit * 3);
}

.dropdown {
  display: block;
}

.popup {
  border: none;
  border-radius: 0;
  background-color: var(--ring-selected-background-color);
  box-shadow: none;
}

.nonInteractivePopup {
  pointer-events: none;
}

.interactivePopup {
  z-index: 0;
}

.highlighting {
  color: var(--ring-link-hover-color);
}

.counter {
  white-space: nowrap;
}

.counterIcon {
  margin-right: 2px;
  margin-left: 6px;

  color: var(--ring-icon-color);
}

.warningIcon {
  margin-left: calc(unit / 2);

  color: var(--ring-icon-error-color);
}

.counterText {
  color: var(--ring-secondary-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
}

.pendingCounter {
  color: var(--ring-main-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: normal;
}

.newFailedTestsCounter {
  color: var(--ring-error-color);

  font-size: var(--ring-font-size-smaller);
  font-weight: bold;
}

.starIcon {
  &.starIcon {
    transition: none;
  }

  &.hovered {
    color: var(--ring-icon-hover-color);
  }
}

.starButtonPopupShowed {
  pointer-events: none;
}

.popup .starButtonPopupShowed {
  pointer-events: initial;
}

.archiveLabel {
  composes: font-smaller-lower;

  margin-right: 2px;
  margin-left: 6px;

  color: var(--ring-secondary-color);
}

.linkIcon {
  margin-right: 4px;

  color: var(--tc-icon-main-color);

  line-height: normal;
}

.updateButton {
  padding: 0;
}

.iconAction {
  padding: 0 3px;

  &:disabled {
    visibility: hidden;
  }
}

.reordered {
  composes: font-smaller-lower;

  padding: 0 2px 0 calc(unit / 2);

  color: var(--ring-secondary-color);
}

.projectsTreeItem.disabled {
  color: var(--ring-disabled-color);
}
