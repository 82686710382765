@value unit: var(--ring-unit);

.headerButton {
  margin-bottom: calc(unit * 3);
  margin-left: calc(unit * -2.5);

  &:hover {
    color: var(--ring-link-hover-color);
  }
}

.backIcon {
  margin-right: 2px;
  padding-top: 1px;

  &.backIcon svg {
    vertical-align: -4px;
  }
}
